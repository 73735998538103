
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.boder {
  content: "";
  position: relative;
  /* background: url("../public/prcy.jpg") no-repeat center center/cover; */
  height: 30vh;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: -1;
  opacity: 0.89;
  background: #242424;
  
}

